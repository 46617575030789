import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { IconButton } from "@mui/material"
import Button from "@scope/standard/ui/Button"
import * as idbkeyval from "idb-keyval"

import { makeInterval } from "@scope/standard/timestuff/makeInterval"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { useContext } from "react"
import Checkbox from "../../components/Checkbox"
import Container from "../../components/Container"
import { ClientContext } from "../../contexts/ClientContext"
import { useConfig } from "../../contexts/ConfigContext"
import { useStore } from "../../state"

interface Props {
  registration: any
}

const Settings = Component(({ registration }: Props) => {
  const store = useStore()
  const client = useContext(ClientContext)!
  const config = useConfig()
  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Checkbox
          checked={"serviceWorker" in navigator}
          readOnly={true}
          disabled={true}
        >
          serviceWorker' in navigator
        </Checkbox>
        <Checkbox checked={!!registration} readOnly={true} disabled={true}>
          registration
        </Checkbox>
        <Checkbox checked={!!client.connected} readOnly={true} disabled={true}>
          connected
        </Checkbox>
        <Checkbox
          checked={!!client.socket?.connected}
          readOnly={true}
          disabled={true}
        >
          socket.connected
        </Checkbox>
        <Checkbox
          checked={store.autoUpload}
          onChange={(event) => store.setAutoUpload(event.target.checked)}
        >
          auto upload
        </Checkbox>
        <Checkbox
          checked={store.online}
          onChange={(event) => store.setOnline(event.target.checked)}
        >
          online
        </Checkbox>
        <Checkbox
          checked={store.isSimpleInterface}
          onChange={(event) =>
            store.setIsSimpleInterface(!store.isSimpleInterface)
          }
        >
          simple interface
        </Checkbox>
      </div>
      <p>
        Build: {process.env.BUILD}{" "}
        {makeInterval(+new Date() - +new Date(process.env.BUILD as string))} ago
      </p>
      <p>
        {/*// @ts-ignore*/}
        Started: {(window.startTime as Date).toISOString()} {/*// @ts-ignore*/}
        {makeInterval(+new Date() - +(window.startTime as Date))} ago
      </p>
      <p>
        Logged in : {store.jwt ? "yes" : "no"}{" "}
        <Button
          onClick={() =>
            document.location.replace(
              `https://accounts.google.com/o/oauth2/v2/auth?client_id=${encodeURIComponent(
                config.clientId
              )}&response_type=code&scope=${encodeURIComponent(
                config.scope
              )}&redirect_uri=${encodeURIComponent(config.redirectUri)}`
            )
          }
        >
          Log in
        </Button>
        <Button onClick={() => idbkeyval.clear().then(() => location.reload())}>
          Log out
        </Button>
      </p>
      <ul>
        <li>
          cards: {store.cards.length} decks: {store.decks.length}{" "}
          <IconButton
            aria-label="delete"
            color="inherit"
            onClick={() =>
              confirm("Are you sure you want to delete all?") &&
              store.deleteAll()
            }
          >
            <DeleteForeverIcon />
          </IconButton>
        </li>
      </ul>
      <div>
        <p>Font Size</p>
        <button onClick={() => store.setFontSize(store.fontSize - 1)}>-</button>
        {store.fontSize}
        <button onClick={() => store.setFontSize(store.fontSize + 1)}>+</button>

        <p>Icon Size</p>
        <button onClick={() => store.setIconSize(store.iconSize - 1)}>-</button>
        {store.iconSize}
        <button onClick={() => store.setIconSize(store.iconSize + 1)}>+</button>
      </div>
    </Container>
  )
})

export default Settings
