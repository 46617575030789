import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ServiceMapContext } from "../../contexts/ServiceMapContext";
import { CardModel, CardType } from "../../../../common/domain/Card";
import { makeInterval } from "@scope/standard/timestuff/makeInterval";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Button from "@scope/standard/ui/Button";
import { useNavigate } from "react-router-dom";

function useHistoryQuery() {
  const serviceMap = useContext(ServiceMapContext)!;
  async function f() {
    const cards = await serviceMap.getHistory(0);
    return cards;
  }
  return useQuery(["useHistoryQuery"], f, { retryDelay: 60e3, enabled: false });
}

function useGetNewestQuery() {
  const serviceMap = useContext(ServiceMapContext)!;
  async function f() {
    const cards = await serviceMap.getNewest(0);
    return cards;
  }
  return useQuery(["useGetNewestQuery"], f, {
    retryDelay: 60e3,
    enabled: false,
  });
}

const HistoryPage = () => {
  const [list, setList] = useState("");
  const historyQuery = useHistoryQuery();
  const getNewestQuery = useGetNewestQuery();
  const navigate = useNavigate();
  useEffect(() => {
    if (list === "lastSeen") {
      historyQuery.refetch();
    }
    if (list === "newest") {
      getNewestQuery.refetch();
    }
  }, [list]);
  const cards = list === "lastSeen" ? historyQuery.data : getNewestQuery.data;
  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        background: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Button onClick={() => setList("lastSeen")}>lastSeen</Button>
        <Button onClick={() => setList("newest")}>newest</Button>
      </div>
      <DataGrid<CardType & { path: string[] }>
        onPageChange={(page, details) => console.log(this)}
        sx={{
          "& .idColumn": { justifyContent: "flex-end" },
          "& .pathColumn": {
            justifyContent: "flex-end",
          },
          "& .MuiDataGrid-cellContent": {
            whiteSpace: "normal",
            lineHeight: 1,
          },
        }}
        columns={[
          {
            field: "path",
            cellClassName: "pathColumn",
            valueGetter: (params) =>
              params.row.path.map((s) => s + " > ").join(""),
            width: 256,
          },
          { field: "front", width: 128 },
          {
            field: "lastSeen",
            valueFormatter: (params) =>
              params.value?.toISOString().slice(0, 16).replace("T", " "),
            width: 140,
          },
          {
            field: "test",
            headerName: "ago",
            valueGetter: (params) => params.row.lastSeen,
            valueFormatter: (params) =>
              params.value
                ? makeInterval(new Date().valueOf() - +params.value)
                : "",
          },
          { field: "id", cellClassName: "idColumn" },
          // { field: "ago" },
        ]}
        rows={cards || []}
        pageSize={8}
        onRowClick={(value) => {
          navigate("/search/" + value.row.id);
        }}
      />
    </Box>
  );
};

export default HistoryPage;
