import { makeInterval } from "@scope/standard/timestuff/makeInterval"
import { pickRandom } from "@scope/standard/utils"
import * as assert from "assert"
import { getParentOfType, Instance, types } from "mobx-state-tree"
import { now } from "mobx-utils"
import { CardModel, CardState, CardType } from "../../../common/domain/Card"
import { StoreModel } from "./Store"

interface DeskAnalysis {
  initial: CardType[]
  available: CardType[]
  idle: CardType[]
  initialNext: CardType | null
  firstToWakeUp: CardType | null
  availableNext: CardType | null
  availableSecond: CardType | null
  idleNext: CardType | null
}

export enum DeckTopState {
  initial = "initial",
  front = "front",
  back = "back",
  both = "both",
}

export interface FindOptions {
  findInAvailable: boolean
  findInNew: boolean
  findInIdle: boolean
  findLastTop: boolean
}

export const DeckModel = types
  .model("Deck", {
    id: types.identifierNumber,
    parentId: types.integer,
    name: types.string,
    state: types.enumeration<DeckTopState>(Object.values(DeckTopState)),
    top: types.maybeNull(types.reference(CardModel)),
    lastTop: types.maybeNull(types.reference(CardModel)),
    cards: types.array(types.reference(CardModel)),
    randomNew: types.optional(types.boolean, false),
    findInNew: types.optional(types.boolean, true),
    findInIdle: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get testableCards() {
      return self.cards.filter((card) => !card.parked)
    },
  }))
  .views((self) => ({
    get cardsByState(): DeskAnalysis {
      console.log(`get cardsByState`)
      const highestPopularity: CardType[] = []
      const highestPriority: CardType[] = []
      const cards = self.testableCards.reduce<DeskAnalysis>(
        (result, f) => {
          if (!f.parked) {
            result[f.state].push(f)
            if (f.state === CardState.idle) {
              if (
                !result.firstToWakeUp ||
                f.idlePeriodEnd! < result.firstToWakeUp.idlePeriodEnd!
              ) {
                result.firstToWakeUp = f
              }
              if (!result.idleNext || f.lastSeen! < result.idleNext.lastSeen!) {
                result.idleNext = f
              }
            } else if (f.state === CardState.available) {
              if (
                !result.availableNext ||
                f.lastSeen! > result.availableNext.lastSeen!
              ) {
                result.availableSecond = result.availableNext
                result.availableNext = f
              } else if (!result.availableSecond) {
                result.availableSecond = f
              }
            } else {
              if (f.combinedPopularity !== null) {
                if (highestPopularity[0]) {
                  if (
                    highestPopularity[0].combinedPopularity! <
                    f.combinedPopularity
                  ) {
                    highestPopularity.length = 0
                    highestPopularity.push(f)
                  } else if (
                    highestPopularity[0].combinedPopularity! ===
                    f.combinedPopularity
                  ) {
                    highestPopularity.push(f)
                  }
                } else {
                  highestPopularity.push(f)
                }
              }
              if (f.priority !== null) {
                if (highestPriority[0]) {
                  if (highestPriority[0].priority! < f.priority) {
                    highestPriority.length = 0
                    highestPriority.push(f)
                  } else if (highestPriority[0].priority! === f.priority) {
                    highestPriority.push(f)
                  }
                } else {
                  highestPriority.push(f)
                }
              }
            }
          }
          return result
        },
        {
          initial: [],
          available: [],
          idle: [],
          initialNext: null,
          firstToWakeUp: null,
          availableNext: null,
          availableSecond: null,
          idleNext: null,
        }
      )
      // cards.idle.sort(
      //   (a, b) =>
      //     (a.idlePeriodEnd ? +a.idlePeriodEnd : 0) -
      //     (b.idlePeriodEnd ? +b.idlePeriodEnd : 0),
      // )
      cards.initialNext = pickRandom(
        highestPopularity.length
          ? highestPopularity
          : highestPriority.length
          ? highestPriority
          : cards.initial
      )
      return cards
    },
  }))
  .views((self) => ({
    get timeout(): number | null {
      return self.cardsByState.firstToWakeUp
        ? Math.max(0, +self.cardsByState.firstToWakeUp.idlePeriodEnd! - now())
        : null
    },
  }))
  .views((self) => ({
    get timeoutString(): string {
      return self.timeout ? makeInterval(self.timeout) : ""
    },
  }))
  .actions((self) => ({
    findNext(
      level: boolean,
      {
        findLastTop = false,
        findInAvailable = true,
        findInIdle = false,
        findInNew = false,
      }: Partial<FindOptions>
    ): CardType | null {
      let result: CardType | null = null
      if (level) {
        const unknown: CardType[] = self.testableCards.filter(
          (flashcard: CardType): boolean => flashcard.state !== CardState.idle
        )
        const nextCard: CardType | void = unknown
          .filter(
            (flashcard: CardType): boolean =>
              !flashcard.parked && self.lastTop !== flashcard
          )
          .filter(
            (flashcard: CardType): boolean =>
              !unknown.some((f: CardType): boolean => f.isAncestorOf(flashcard))
          )
          .sort((a: CardType, b: CardType): number => {
            // const cmpLevel = a.getDeepestDepth() - b.getDeepestDepth();
            return -(
              (a.lastSeen ? +a.lastSeen : 0) - (b.lastSeen ? +b.lastSeen : 0)
            )
          })[0]
        assert.ok(nextCard)
        result = nextCard
      } else {
        if (findInAvailable) {
          result = self.cardsByState.availableNext
          if (!findLastTop && self.lastTop === result) {
            result = self.cardsByState.availableSecond
          }
        }
        if (!result && findInNew) {
          result = self.cardsByState.initialNext
        }
        if (!result && findInIdle) {
          result = self.cardsByState.idleNext
        }
      }
      return result
    },
  }))
  .actions((self) => ({
    setTop(flashcard: CardType | null) {
      self.top = flashcard
      if (self.top) {
        self.lastTop = self.top
      }
    },
  }))
  .actions((self) => ({
    show(options: Partial<FindOptions> = {}) {
      const newTop = self.findNext(false, {
        ...options,
        findInNew: options.findInNew ?? self.findInNew,
        findInIdle: options.findInIdle ?? self.findInIdle,
      })
      self.setTop(newTop)
      if (self.top) {
        if (self.top.state === CardState.idle) {
          self.top.wakeUp()
        }
        self.top.registerView(true)
        self.state = self.top.reverse ? DeckTopState.back : DeckTopState.front
      }
    },
  }))
  .actions((self) => ({
    showOther() {
      if (self.top) {
        self.state = DeckTopState.both
        self.top.registerView()
      }
    },
    keep(findNext: boolean) {
      if (self.top) {
        getParentOfType(self, StoreModel).addEvent(self.top, "keep")
        self.top.registerView()
        self.setTop(null)
      }
      self.state = DeckTopState.initial
      if (findNext) {
        self.show({ findInNew: false, findInIdle: false })
      }
    },
    discard(findNext: boolean) {
      if (self.top) {
        self.top.registerView()
        self.top.setIdlePeriodEnd()
        self.state = DeckTopState.initial
        self.setTop(null)
        if (findNext) {
          self.show({ findInNew: false, findInIdle: false })
        }
      }
    },
    setState(state: DeckTopState) {
      self.state = state
    },
    setRandomNew(randomNew: boolean) {
      self.randomNew = randomNew
    },
    setFindInNew(findInNew: boolean) {
      self.findInNew = findInNew
    },
    setFindInIdle(findInIdle: boolean) {
      self.findInIdle = findInIdle
    },
    setFlashcards(flashcards: CardType[]) {
      self.cards.replace(flashcards)
    },
  }))

export interface DeckType extends Instance<typeof DeckModel> {}
