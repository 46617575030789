import Component from "@scope/standard/ui/Component";
import { CardType } from "../../../../common/domain/Card";
import Textarea from "../../components/Textarea";
import Container from "../../components/Container";
import * as React from "react";
import { MenuItem, Select } from "@mui/material";
import Button from "@scope/standard/ui/Button";
import { useService } from "../../contexts/ServiceMapContext";

const parseCards = (text: string) => {
  const cardTexts = text
    .trim()
    .split(/\n\s*\n/)
    .map((card) => card.trim());
  const cards: any[] = [];
  for (const card of cardTexts) {
    if (card) {
      const match = card.match(/(.+?)\n(.+)/s);
      if (match) {
        const [, front, back] = match;
        cards.push({ front, back });
        console.log(front, back);
      } else {
        throw new Error("wrong card parse: " + card);
      }
    }
  }
  return cards;
};

export default Component(function ({
  card,
  handleGetChildren,
}: {
  card: CardType;
  handleGetChildren: () => void;
}) {
  const [reverse, setReverse] = React.useState("null");
  const [text, setText] = React.useState<string>("");
  const serviceMap = useService();
  const parse = async () => {
    if (card) {
      await serviceMap.createCard(card.id, parseCards(text), {
        reverse: reverse === "null" ? null : reverse === "true",
      });
      await handleGetChildren();
    }
  };
  let parseResult;
  try {
    parseResult = "" + parseCards(text).length;
  } catch (e) {
    parseResult = "error";
  }
  return (
    <Container>
      <p>Mass input</p>
      <Textarea
        onChange={(event) => setText(event.target.value)}
        value={text}
        style={{ width: "100%", height: "100px" }}
      />
      Create {parseResult} children for {card.id}
      <Select
        onChange={(event: any) => setReverse(event.target.value)}
        value={reverse}
      >
        <MenuItem value={"null"}>null</MenuItem>
        <MenuItem value={"true"}>true</MenuItem>
        <MenuItem value={"false"}>false</MenuItem>
      </Select>
      <Button onClick={parse}>Parse</Button>
    </Container>
  );
});
