import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { CardType } from "../../../../common/domain/Card";
import { useCrumbQuery } from "../../queryHooks";

interface Props {
  setTop: (card: CardType | null) => void;
  card: CardType["id"] | null;
}

const CardPath = ({ card, setTop }: Props) => {
  const crumbQuery = useCrumbQuery(card);
  return (
    <Breadcrumbs>
      <Link onClick={() => setTop(null)}>Top</Link>
      {(crumbQuery.data || []).map((ancestor) => (
        <Link key={ancestor.id} onClick={() => setTop(ancestor)}>
          {ancestor.front}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default CardPath;
