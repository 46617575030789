import AddIcon from "@mui/icons-material/Add"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import { Container } from "@mui/material"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CardType } from "../../../../common/domain/Card"
import Textarea from "../../components/Textarea"
import { ClientContext } from "../../contexts/ClientContext"
import { ServiceMapContext } from "../../contexts/ServiceMapContext"
import { useStore } from "../../state"
import { installDeck } from "../decks/DeckList"
import CardList from "./CardList"
import CardPath from "./CardPath"
import MassInput from "./MassInput"

const Search = Component(() => {
  const client = useContext(ClientContext)!
  const [cards, setCards] = useState<CardType[]>([])
  const [selected, setSelectedCard] = useState<CardType | null>(null)
  const serviceMap = useContext(ServiceMapContext)!
  const store = useStore()
  const [bookmark] = useState<CardType | null>(null)
  const { card } = useParams()
  useEffect(() => {
    if (typeof card === "string" && Number.isInteger(+card)) {
      store.setSearchTop(+card)
    }
  }, [card])
  const handleSearch = async () => {
    setCards([])
    const cards = await serviceMap.getCardsByParent(
      store.searchTop === null ? null : store.searchTop
    )
    setCards(cards)
    return cards
  }

  const setTop = async (top: CardType | null) => {
    store.setSearchTop(top ? top.id : null)
    setSelectedCard(null)
  }

  const handleGetChildren = async () => {
    if (selected) {
      setTop(selected)
    }
  }

  const handleClick = async (card: CardType) => {
    setSelectedCard(selected === card ? null : card)
  }

  const handleDoubleClick = async (card: CardType) => {
    setTop(card)
  }

  const handleLoad = async () => {
    if (selected && confirm("Are you sure you want to create a deck?")) {
      await installDeck(serviceMap, store, selected)
    }
  }

  async function handleCreate() {
    const cardIds = await serviceMap.createCard(
      selected ? selected.id : store.searchTop,
      [{ front: "", back: "" }],
      {}
    )
    const cards = await handleSearch()
    const card = cards.find((card) => card.id === cardIds[0])!
    setSelectedCard(card)
  }

  const handleDelete = async () => {
    if (selected && confirm("Are you sure you want to delete this card?")) {
      await serviceMap.remove(selected.id)
      setSelectedCard(null)
      await handleSearch()
    }
  }

  useEffect(() => {
    handleSearch()
  }, [store.searchTop])
  const changeParent = async () => {
    await serviceMap.changeParent(selected!.id, bookmark!.id)
  }

  if (client.websocket && !client.connected) {
    return <div>Not connected</div>
  }
  return (
    <div>
      <CardPath card={store.searchTop} setTop={setTop} />
      <CardList
        cards={cards}
        selected={selected}
        handleClick={handleClick}
        handleDoubleClick={handleDoubleClick}
      />
      <div>
        <Button disabled={!selected} onClick={handleLoad}>
          <SubscriptionsIcon />
          <AddIcon />
        </Button>
        <Button disabled={!selected} onClick={handleGetChildren}>
          <ArrowDownward />
        </Button>
        <Button onClick={handleCreate}>+</Button>
        <Button disabled={!selected} onClick={handleDelete}>
          <DeleteForeverIcon />
        </Button>
      </div>
      <Container>
        <Textarea
          style={{ display: "block", minWidth: "32em" }}
          disabled={!selected}
          value={selected ? selected.front : "<front>"}
          onChange={(event) => selected?.update({ front: event.target.value })}
        />
        <Textarea
          style={{ display: "block", minWidth: "32em" }}
          disabled={!selected}
          value={selected ? selected.back : "<back>"}
          onChange={(event) => selected?.update({ back: event.target.value })}
        />
        <Button
          onClick={() =>
            selected &&
            serviceMap.saveScore([
              { id: selected.id, front: selected.front, back: selected.back },
            ])
          }
        >
          Save
        </Button>
      </Container>
      {!!selected && (
        <div>
          <MassInput card={selected} handleGetChildren={handleGetChildren} />
        </div>
      )}
    </div>
  )
})

export default Search
