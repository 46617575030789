const config = {
  mobx: {
    useProxies: "always",
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
  },
} as { mobx: any };

export default config;
