import "core-js";
// @ts-ignore
if (!globalThis.Buffer) {
  // @ts-ignore
  globalThis.Buffer = require("buffer/").Buffer;
}
import "reflect-metadata";
import { configure } from "mobx";
import config from "./config";

configure(config.mobx);
