import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import { Box, Toolbar } from "@mui/material"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import * as assert from "assert"
import * as React from "react"
import { DeckType } from "../../model/Deck"
import { useStore } from "../../state"

export default Component(function ({ deck }: { deck: DeckType }) {
  const store = useStore()
  const changeDeck = () => {
    if (store.decks.length > 1) {
      const index = store.decks.indexOf(deck)
      const newIndex = (index + 1) % store.decks.length
      const newDeck = store.decks[newIndex]
      assert.ok(newDeck)
      store.setSelectedDeck(newDeck)
    }
  }
  return (
    <Toolbar style={{ justifyContent: "space-between" }}>
      {store.isSimpleInterface && <Box>Deck: {deck.name}</Box>}
      {!store.isSimpleInterface && (
        <Button onClick={changeDeck}>
          <SubscriptionsIcon />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              lineHeight: 1,
            }}
          >
            <div>{deck.name}</div>
            <div>{deck.timeout}</div>
          </div>
        </Button>
      )}
    </Toolbar>
  )
})
